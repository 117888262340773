import IconResidentialnAgent from '@icon/home/type-residential-agents.svg'
import IconRuralAgents from '@icon/home/type-rural-agents.svg'
import IconCommercialAgents from '@icon/home/type-commercial-agents.svg'
import IconBusinessesSale from '@icon/home/type-businesses-sale.svg'
import IconPropertyManagers from '@icon/home/type-property-managers.svg'
import IconOffices from '@icon/home/type-offices.svg'

import IconBuy from '@icon/home/type-buy.svg'
import IconEstimate from '@icon/home/type-estimate.svg'
import IconRent from '@icon/home/type-rent.svg'
import IconSold from '@icon/home/type-sold.svg'
import IconSuburb from '@icon/home/type-suburb.svg'

import IconApartments from '@icon/home/type-apartments.svg'
import IconLand from '@icon/home/type-house-land.svg'
import IconBuilders from '@icon/home/type-builders.svg'

import IconCommercialSale from '@icon/home/type-commercial-sale.svg'
import IconCommercialLease from '@icon/home/type-commercial-lease.svg'

import IconSpecialistCareHomes from '@icon/home/type-specialist-care-homes.svg'
import IconServicedApartments from '@icon/home/type-serviced-apartments.svg'
import IconIndependentLiving from '@icon/home/type-independent-living.svg'
import IconAssistedLiving from '@icon/home/type-assisted-living.svg'

import { getAgentSearchType, getBusinessesSearchType, getCommercialSearchType, getNewHomeSearchType, getResidentialSearchType, getRuralSearchType, getSuburbSearchType, getVillageSearchType } from './content'

// Residential filter
export const RESIDENTIAL_FILTER = {
  searchTypes: [
    getResidentialSearchType(0, { Icon: IconBuy }),
    getResidentialSearchType(1, { Icon: IconEstimate }),
    getResidentialSearchType(2, { Icon: IconRent }),
    getResidentialSearchType(3, { Icon: IconSold }),
    getResidentialSearchType(4, { Icon: IconSuburb })
  ],
  url: 'houses/filter',
  confirmText: 'Show properties',
  confirmTotalText: 'Show {totals} properties'
}

// Agent filter
export const AGENT_FILTER = {
  searchTypes: [
    getAgentSearchType(0, { Icon: IconResidentialnAgent }),
    getAgentSearchType(1, { Icon: IconRuralAgents }),
    getAgentSearchType(2, { Icon: IconCommercialAgents }),
    getAgentSearchType(3, { Icon: IconBusinessesSale }),
    getAgentSearchType(4, { Icon: IconPropertyManagers }),
    getAgentSearchType(5, { Icon: IconOffices }),
  ],
  url: 'agents/filter',
  confirmText: 'Confirm',
  confirmTotalText: 'Show {totals} agents'
}

// new homes filter
export const NEW_HOMES_FILTER = {
  searchTypes: [
    getNewHomeSearchType(0, { Icon: IconApartments }),
    getNewHomeSearchType(1, { Icon: IconLand }),
    getNewHomeSearchType(2, { Icon: IconBuilders }),
  ],
  url: 'houses/filter',
  confirmText: 'Confirm',
  confirmTotalText: 'Show {totals} properties'
}

// commercial filter
export const COMMERCIAL_FILTER = {
  searchTypes: [
    getCommercialSearchType(0, { Icon: IconCommercialSale }),
    getCommercialSearchType(1, { Icon: IconCommercialLease }),
  ],
  url: 'houses/filter',
  confirmText: 'Confirm',
  confirmTotalText: 'Show {totals} properties'
}

// businesses filter
export const BUSINESSES_FILTER = {
  searchTypes: [
    getBusinessesSearchType(0, { Icon: IconBusinessesSale }),
  ],
  url: 'houses/filter',
  confirmText: 'Confirm',
  confirmTotalText: 'Show {totals} properties'
}

// rural filter
export const RURAL_FILTER = {
  searchTypes: [
    getRuralSearchType(0, { Icon: IconBusinessesSale })
  ],
  url: 'houses/filter',
  confirmText: 'Confirm',
  confirmTotalText: 'Show {totals} properties'
}

// suburb filter
export const SUBURB_FILTER = {
  searchTypes: [
    getSuburbSearchType(0, { Icon: IconSuburb })
  ]
}

// village filter
export const VILLAGE_FILTER = {
  searchTypes: [
    getVillageSearchType(0, { Icon: null }),
    getVillageSearchType(1, { Icon: IconIndependentLiving }),
    getVillageSearchType(2, { Icon: IconServicedApartments }),
    getVillageSearchType(3, { Icon: IconAssistedLiving }),
    getVillageSearchType(4, { Icon: IconSpecialistCareHomes }),
  ],
  url: 'houses/filter',
  confirmText: 'Show properties',
  confirmTotalText: 'Show {totals} properties'
}
